"use client";

import React from "react";
import PropTypes from "prop-types";
import { flexRender } from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../Table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  DoubleArrowNext,
  DoubleArrowPrev,
  PaginationNumber,
} from "../../ui/Pagination";

const DataTable = ({ columns, table, loading }) => {
  return (
    <>
      <Table data-testid="DataTable">
        <TableHeader className="bg-white capitalize">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="overflow-y-auto">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="capitalize text-left">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4 px-4">
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <DoubleArrowPrev
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationPrevious
                onClick={() => {
                  table.previousPage();
                }}
                disabled={!table.getCanPreviousPage() || loading}
              />
            </PaginationItem>
            {/*render number*/}
            {PaginationNumber(
              table.getState().pagination.pageIndex,
              table.getPageCount(),
              (idx) => table.setPageIndex(idx)
            )}

            <PaginationItem>
              <PaginationNext
                onClick={() => {
                  table.nextPage();
                }}
                disabled={!table.getCanNextPage() || loading}
              />
            </PaginationItem>
            <PaginationItem>
              <DoubleArrowNext
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage() || loading}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
};

export default DataTable;
