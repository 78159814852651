import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import CardStack from "../../components/ui/CardStack";
import { numberWithoutIDR } from "../../lib/utils";

const sortByCustomOrder = (data, customOrder) => {
  if (!Array.isArray(customOrder) || customOrder.length === 0) {
    return data;
  }

  return data.sort((a, b) => {
    const indexA = customOrder.indexOf(a.title);
    const indexB = customOrder.indexOf(b.title);

    const posA = indexA === -1 ? customOrder.length : indexA;
    const posB = indexB === -1 ? customOrder.length : indexB;

    return posA - posB;
  });
};

const InfoHeaderCustomRow = ({
  infoData = [],
  infoFormat,
  queueFormat = [],
  rowConfig,
}) => {
  const formattedData = sortByCustomOrder([...infoData], queueFormat);

  const itemsWithRows = formattedData.map((item) => ({
    ...item,
    row: rowConfig[item.title] || 4,
  }));

  const rows = [[], [], [], []];

  itemsWithRows.forEach((item) => {
    const rowIndex = item.row - 1;
    if (rowIndex >= 0 && rowIndex < 4) {
      rows[rowIndex].push(item);
    }
  });

  return (
    <Flex
      data-testid="InfoHeaderCustomRow"
      direction="column"
      gap={4}
      my={4}
      mx={4}
    >
      {/* Render 3 rows */}
      {rows.map((rowItems, rowIndex) => (
        <Flex
          key={`row-${rowIndex}`}
          direction="row"
          wrap="wrap"
          gap={4}
          justify="center"
          align="stretch"
        >
          {rowItems.map((item, idx) => (
            <Box key={`${rowIndex}-${idx}`} flex="1" minW="17%">
              <CardStack
                bodyClassName={`${
                  infoFormat?.bgColor
                    ? infoFormat?.bgColor[item?.title?.toLowerCase()] ||
                      "bg-gray-200"
                    : "bg-gray-200"
                } w-full z-1`}
                textBodyClassName={`${item.row === 3 ? "text-xl" : ""}`}
                footerClassName={`bg-green-600`}
                bodyData={numberWithoutIDR(item.amount)}
                footerData={item.footerData || []}
                title={
                  infoFormat?.title
                    ? infoFormat?.title[item?.title?.toLowerCase()] ||
                      item.title
                    : item.title
                }
              />
            </Box>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export default InfoHeaderCustomRow;
