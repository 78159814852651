import { useCallback, useState } from "react";
import axios from "axios";
import { useAuth } from "./useAuth";
import { handleError } from "../lib/utils";
import { useLoading } from "./LoadingContext";

export const useFetchManualTrigger = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const { logout } = useAuth();

  const fetchData = useCallback(
    async (api, params = {}) => {
      setLoading(true);
      startLoading();
      try {
        const res = await axios.get(api, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params, // Allows passing query parameters for search
        });

        if (!res.status === 200 || res.data.success === false) {
          setError({
            ...res.data,
            message: res.data.message || "Something went wrong!!",
          });
        }

        setData(res.data);
        setError(null);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          logout();
        }
        setError(handleError(error));
      }
      setLoading(false);
      stopLoading();
    },
    [user.token, startLoading, stopLoading, logout]
  );

  return {
    fetchData, // Expose the function for manual triggering
    data,
    loading,
    error,
  };
};
