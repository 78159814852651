import React, { useEffect, useState } from "react";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Datepicker from "react-tailwindcss-datepicker";
import { dateFormat, numberToIDR } from "../../lib/utils";
import { useApiPost } from "../../hooks/useApiPost";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import { Label } from "../../components/ui/Label";
import LabelWrapper from "../../components/ui/LabelWrapper";
import { IcBaselineVerified } from "../../components/ui/icon";
import "moment/min/locales";
import CakraInput from "../../components/CakraInput";
import { useAuth } from "../../hooks/useAuth";
import { SKU_PREMIUM } from "../../lib/constant";
import ModalConfirmation from "../../components/ModalConfirmation";
import { useFetchData } from "../../hooks/useFetchData";
import toastHandler from "../../components/toastHandler";
import { useNavigate } from "react-router-dom";
import { AsyncSelect } from "chakra-react-select";
import PaymentForm from "../helpdesk/PaymentForm";

const AdminPembelianTiket = () => {
  const [vipTotalPrice, setVipTotalPrice] = useState(0);
  const [regularTotalPrice, setRegularTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isPaymentForm, setIsPaymentForm] = useState(false);
  const [checked, setChecked] = useState();
  const [taDataOptions, setTaDataOptions] = useState([
    { value: "umum", label: "Umum" },
  ]);
  const [salesDataOptions, setSalesDataOptions] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const {
    handlePost: submitPost,
    loading: submitLoading,
    data: submitData,
    error: submitError,
  } = useApiPost();

  const { user } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    loading: taLoading,
    data: taData,
    error: taError,
  } = useFetchData("/api/helpdesk-travel-agents");

  const {
    loading: salesLoading,
    data: salesData,
    error: salesError,
  } = useFetchData("/api/sales");

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (watch("vip") || watch("regular")) {
      displayPrice();
    }
  }, [watch("vip"), watch("regular"), watch("selectTravelAgent")]);

  useEffect(() => {
    if (!submitLoading && submitData) {
      navigate("/helpdesk/dashboard");
    }
    toastHandler({
      data: submitData,
      error: submitError,
      loading: submitLoading,
      toast: toast,
      title: "Transaksi berhasil",
    });
  }, [submitData, submitLoading, submitError]);

  useEffect(() => {
    if (!taLoading && taData) {
      let options = [...taDataOptions];
      taData.data.map((item) => {
        options.push({
          value: item.travel_agent_id,
          label: `${item.pic_name} - ${item.name}`,
        });
      });
      setTaDataOptions(options);
    }
  }, [taLoading, taData]);

  useEffect(() => {
    if (!salesLoading && salesData && !salesError) {
      let options = [];
      salesData.data.map((item) => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      setSalesDataOptions(options);
      setValue("selectSales", options[0]);
    }
  }, [salesLoading, salesData]);

  const onSubmit = (data) => {
    const {
      selectTravelAgent,
      selectSales,
      namaLeader,
      phone,
      email,
      kodePenerbangan,
      namaGroupUmroh,
      vip,
      vipPrice,
      regular,
      regularPrice,
      tglPakai,
      cardNumber,
      bank,
      noRevTransaction,
      isCheckin,
    } = data || {};
    const payload = {
      travel_agent_id:
        selectTravelAgent?.value !== "umum" ? selectTravelAgent.value : "0",
      email,
      tour_leader_name: namaLeader,
      group_name: namaGroupUmroh,
      phone_number: phone,
      flight_number: kodePenerbangan,
      use_date: dateFormat(tglPakai.startDate),
      sku_list: [
        {
          name: "Reguler",
          qty: parseInt(regular),
          price: parseInt(regularPrice),
        },
        {
          name: "Premium",
          qty: parseInt(vip),
          price: parseInt(vipPrice),
        },
      ],
      payment_type: checked,
      no_rev_transaction: noRevTransaction,
      bank: bank,
      card_no: cardNumber,
      is_checkin: isCheckin === "true" ? true : false,
      ...(selectTravelAgent?.value !== "umum"
        ? null
        : { sales_id: selectSales.value }),
    };
    setIsPaymentForm(false);
    setChecked(null);
    onClose();

    submitPost(`/api/reservations`, payload);
  };

  const preventNegativeValue = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const onVipChange = (e) => {
    if (e.target.value < 0) {
      setValue("vip", 0);
    }
    setValue("vip", e.target.value);
  };

  const onVipPriceChange = (e) => {
    if (e.target.value < 0) {
      setValue("vipPrice", 0);
    }
    setValue("vipPrice", e.target.value);
  };

  const onRegularPriceChange = (e) => {
    if (e.target.value < 0) {
      setValue("regularPrice", 0);
    }
    setValue("regularPrice", e.target.value);
  };

  const onRegularChange = (e) => {
    if (e.target.value < 0) {
      setValue("regular", 0);
    }
    setValue("regular", e.target.value);
  };

  const onVipIncrease = () => {
    setValue("vip", getValues("vip") + 1);
  };

  const onVipDecrease = () => {
    setValue("vip", getValues("vip") > 0 ? getValues("vip") - 1 : 0);
  };

  const onRegularIncrease = () => {
    setValue("regular", getValues("regular") + 1);
  };

  const onRegularDecrease = () => {
    setValue(
      "regular",
      getValues("regular") > 0 ? getValues("regular") - 1 : 0
    );
  };

  const displayPrice = () => {
    const vip_price = watch("vipPrice");
    const regular_price = watch("regularPrice");

    const subTotal = Math.ceil(
      watch("vip") * vip_price + watch("regular") * regular_price
    );

    const total = Math.ceil(subTotal);

    setVipTotalPrice(Math.ceil(watch("vip") * vip_price));
    setRegularTotalPrice(Math.ceil(watch("regular") * regular_price));
    setSubTotalPrice(subTotal);
    setTotalPrice(total);
    setValue("amount", total);
  };

  const handleChecked = (e) => {
    setChecked(e);
    setValue("cardNumber", "");
    setValue("bank", "");
    setValue("noRevTransaction", "");
  };

  const handleIsDisabledPembayaran = () => {
    const watchBank = watch("bank");
    const watchCardNum = watch("cardNumber");
    const watchNoRevTrans = watch("noRevTransaction");
    let isDisabled = false;
    if (!checked) return true;
    if (checked === "debit") {
      if (watchBank === "" || watchCardNum === "" || watchNoRevTrans === "") {
        isDisabled = true;
      }
    }
    if (checked === "qris" || checked === "va") {
      if (watchNoRevTrans === "") {
        isDisabled = true;
      }
    }

    return isDisabled;
  };

  const onHandleBack = () => {
    setIsPaymentForm(false);
  };

  const onToggleConfirmation = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const onSubmitChangeForm = (data) => {
    if (parseInt(data.vip) === 0 && parseInt(data.regular) === 0) {
      setError("vip", {
        type: "manual",
        message: `${SKU_PREMIUM} dan Regular tidak boleh keduanya nol`,
      });
      setError("regular", {
        type: "manual",
        message: `${SKU_PREMIUM} dan Regular tidak boleh keduanya nol`,
      });
    } else {
      setIsPaymentForm(true);
    }
  };

  const loadOptionsTa = (inputValue, callback) => {
    if (!taData) return [];
    const data = taData.data
      .filter(
        (item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.pic_name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: `${item.pic_name} - ${item.name}`,
        value: item.travel_agent_id,
      }));
    callback(data);
  };

  const loadOptionsSales = (inputValue, callback) => {
    if (!salesData) return [];
    const data = salesData.data
      .filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
    callback(data);
  };

  const travelUmum = watch("selectTravelAgent")?.value;

  return (
    <form>
      <div className="w-full grid grid-cols-3">
        <div id="form-1" className="col-span-2 mx-4">
          {!isPaymentForm ? (
            <>
              <div className="mb-4">
                <h1 className="text-2xl font-normal">Detail Pemesanan</h1>
                <h3 className="text-base font-normal text-systemGrey">
                  Isi formulir ini dengan benar karena e-tiket akan dikirim ke
                  WA / alamat email sesuai data leader.
                </h3>
              </div>

              <div className="flex flex-col gap-4">
                <CakraInput
                  label="Pilih Checkin"
                  labelHelp="pilih checkin jika langsung ter-checkin"
                >
                  <Controller
                    name="isCheckin"
                    control={control}
                    defaultValue={"false"}
                    rules={{
                      required: {
                        value: true,
                        message: "pilih salah satu",
                      },
                    }}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        defaultValue={"false"}
                        className="border rounded-sm px-3 py-3"
                      >
                        <Stack spacing={5} direction="row">
                          <Radio colorScheme="green" value={"true"}>
                            Checkin
                          </Radio>
                          <Radio colorScheme="red" value={"false"}>
                            Tidak di Checkin
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </CakraInput>
                <CakraInput
                  label="Pilih travel"
                  labelHelp={`pilih "Umum" jika tidak terikat agent`}
                >
                  <Controller
                    name="selectTravelAgent"
                    control={control}
                    defaultValue={taDataOptions[0]}
                    rules={{
                      required: {
                        value: true,
                        message: "pilih salah satu",
                      },
                    }}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        isClearable
                        defaultOptions={taDataOptions}
                        cacheOptions
                        loadOptions={loadOptionsTa}
                      />
                    )}
                  />
                </CakraInput>
                {travelUmum === "umum" ? (
                  <CakraInput label="Pilih sales">
                    <Controller
                      name="selectSales"
                      control={control}
                      defaultValue={salesDataOptions[0]}
                      rules={{
                        required: {
                          value: watch("selectTravelAgent") === "umum",
                          message: "pilih salah satu",
                        },
                      }}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          isClearable
                          defaultOptions={salesDataOptions}
                          cacheOptions
                          loadOptions={loadOptionsSales}
                        />
                      )}
                    />
                  </CakraInput>
                ) : null}
                <CakraInput
                  label={"Nama leader"}
                  labelHelp={"Nama lengkap sesuai KTP"}
                  {...register("namaLeader", { required: true })}
                  placeholder="Tulis nama leader umroh"
                  error={errors.namaLeader}
                />
                <CakraInput
                  label={"Nomor ponsel aktif WA"}
                  {...register("phone", {
                    required: true,
                    pattern: {
                      value: /^(?:\+62|62|0)8[1-9][0-9]{6,11}$/,
                      message: "tolong isikan nomor hp yang benar",
                    },
                  })}
                  error={errors.phone}
                  placeholder="Dimulai dengan 0 atau 62"
                />
                <CakraInput
                  label={"Alamat email"}
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "isi email yang benar",
                    },
                  })}
                  placeholder="Alamat email"
                  maxLength={50}
                  error={errors.email}
                />
                <CakraInput
                  label={"Nomor penerbangan"}
                  {...register("kodePenerbangan", { required: true })}
                  placeholder="Isi nomor penerbangan pesawat umroh"
                  error={errors.kodePenerbangan}
                />
                <CakraInput
                  label={"Nama group umroh"}
                  {...register("namaGroupUmroh", { required: true })}
                  placeholder="Isi nama group jamaah umroh dengan unik"
                  error={errors.namaGroupUmroh}
                  max={255}
                />
                <CakraInput
                  label={"Waktu Penggunaan area Lounge"}
                  error={errors.tglPakai}
                >
                  <Controller
                    control={control}
                    name="tglPakai"
                    defaultValue={null}
                    rules={{
                      validate: (v) => {
                        if (
                          v === null ||
                          v.startDate === undefined ||
                          v.startDate === null
                        ) {
                          return "tolong isi tanggal dipakai";
                        }
                      },
                    }}
                    render={({ field }) => (
                      <Datepicker
                        useRange={false}
                        asSingle={true}
                        displayFormat="DD MMM YYYY"
                        popoverDirection="up"
                        inputClassName={
                          "bg-inherit text-black w-full border-inherit rounded-lg"
                        }
                        placeholder="Pilih tanggal penggunaan"
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        readOnly={true}
                      />
                    )}
                  />
                </CakraInput>
                <Label className="border-b-2 pb-4 text-lg font-semibold">
                  Pilih tipe pesanan
                </Label>
                {/* <div className="flex"> */}
                <FormControl className="flex flex-row gap-3">
                  <div className="basis-1/2 flex flex-col gap-3 capitalize">
                    <CakraInput
                      label={"Premium Price / pax"}
                      error={errors.vip}
                    >
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("vipPrice", {
                            value: 0,
                            min: 0,
                            onChange: onVipPriceChange,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </NumberInput>
                    </CakraInput>
                    {/* <FormHelperText mt={"1px"} mb={"0.5rem"}>
                      {`${SKU_PREMIUM} Normal `} Input field
                    </FormHelperText>
                    <div className="text-base font-semibold">
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("vipPrice", {
                            value: 0,
                            min: 0,
                            onChange: onVipChange,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </NumberInput>
                    </div> */}
                  </div>
                  <div className="basis-1/2">
                    <CakraInput label={"Jumlah tiket"} error={errors.vip}>
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("vip", {
                            value: 0,
                            min: 0,
                            onChange: onVipChange,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper onClick={onVipIncrease} />
                          <NumberDecrementStepper onClick={onVipDecrease} />
                        </NumberInputStepper>
                      </NumberInput>
                    </CakraInput>
                  </div>
                </FormControl>
                <FormControl className="flex flex-row mb-10 gap-3">
                  <div className="basis-1/2 flex flex-col gap-3">
                    <CakraInput
                      label={"Regular Price / Pax"}
                      error={errors.vip}
                    >
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("regularPrice", {
                            value: 0,
                            min: 0,
                            onChange: onRegularPriceChange,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </NumberInput>
                    </CakraInput>
                    {/* <FormHelperText mt={"1px"} mb={"0.5rem"}>
                      {`Regular Normal `} Input Price
                      {`/pax`}
                    </FormHelperText>
                    <div className="text-base font-semibold">
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("regularPrice", {
                            value: 0,
                            min: 0,
                            onChange: onVipChange,
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </NumberInput>
                    </div> */}
                  </div>
                  <div className="basis-1/2">
                    <CakraInput label={"Jumlah tiket"} error={errors.regular}>
                      <NumberInput min={0}>
                        <NumberInputField
                          {...register("regular", {
                            value: 0,
                            min: 0,
                            onChange: onRegularChange,
                          })}
                          onKeyDown={preventNegativeValue}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper onClick={onRegularIncrease} />
                          <NumberDecrementStepper onClick={onRegularDecrease} />
                        </NumberInputStepper>
                      </NumberInput>
                    </CakraInput>
                  </div>
                </FormControl>
                {/* </div> */}
              </div>
            </>
          ) : (
            <PaymentForm
              checked={checked}
              setChecked={handleChecked}
              register={register}
              totalPrice={totalPrice}
              onHandleBack={onHandleBack}
            />
          )}
        </div>
        <div id="fomr-2" className="mx-3">
          <Card x-chunk="dashboard-07-chunk-3" className="bg-green-100 ">
            <CardHeader className="pt-5 pr-0">
              <CardTitle className="flex items-center gap-2 text-lg">
                Preview Pesanan
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              <div className="flex flex-col sm:gap-6 p-6 pt-0">
                <LabelWrapper className="text-sm font-normal">
                  Nama Leader
                  <article className="text-pretty max-w-2xs">
                    <h3 className="capitalize">{watch("namaLeader") || "-"}</h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Nomor ponsel{" "}
                  <h3 className="capitalize">{watch("phone") || "-"}</h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal justify-between">
                  Email
                  <article className="text-pretty max-w-2xs">
                    <h3>{watch("email") || "-"}</h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Nama Group Umroh{" "}
                  <article className="text-pretty max-w-2xs">
                    <h3 className="capitalize">
                      {watch("namaGroupUmroh") || "-"}
                    </h3>
                  </article>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Waktu Penggunaan
                  <h3>
                    {(watch("tglPakai") &&
                      moment(watch("tglPakai").startDate).format(
                        "DD MMM YYYY"
                      )) ||
                      "-"}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal capitalize">
                  {SKU_PREMIUM} x {watch("vip")} pax
                  <h3 className="capitalize">
                    {numberToIDR(vipTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Regular x {watch("regular")} pax
                  <h3 className="capitalize">
                    {numberToIDR(regularTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <LabelWrapper className="text-sm font-normal">
                  Sub total
                  <h3 className="capitalize">
                    {numberToIDR(subTotalPrice || 0)}
                  </h3>
                </LabelWrapper>
              </div>
              <div>
                <LabelWrapper className="bg-yellow-200 px-6 py-4 mx-0">
                  Total Pembayaran{" "}
                  <h3 className="font-bold text-base">
                    {numberToIDR(totalPrice || 0)}
                  </h3>
                </LabelWrapper>
                <div className="px-6 pt-6 pb-4">
                  {!isPaymentForm ? (
                    <Button
                      id="pay"
                      type="button"
                      variant={"default"}
                      size="lg"
                      className="w-full !text-base !font-semibold text-white bg-green-600"
                      onClick={handleSubmit(onSubmitChangeForm)}
                    >
                      Lanjut Pembayaran
                    </Button>
                  ) : (
                    <Button
                      id="pay"
                      type="button"
                      colorScheme="bg-green-600"
                      size="lg"
                      className="w-full !text-base !font-semibold text-white bg-green-600"
                      isDisabled={handleIsDisabledPembayaran()}
                      onClick={onToggleConfirmation}
                    >
                      Pembayaran Selesai
                    </Button>
                  )}
                  <div className="flex flex-row text-center gap-1 p-3 justify-center items-center pb-0">
                    <IcBaselineVerified className="text-systemAzure" />{" "}
                    Transaksi aman dan nyaman
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <ModalConfirmation
          onToggleModal={onToggleConfirmation}
          isOpen={isOpen}
          buttonSubmit={
            <Button
              id="pay"
              type="submit"
              colorScheme="bg-green-600"
              size="md"
              className="!text-base !font-semibold text-white bg-green-600"
              onClick={handleSubmit(onSubmit)}
            >
              {submitLoading ? "Loading..." : "Ya, sesuai"}
            </Button>
          }
        />
      </div>
    </form>
  );
};

export default AdminPembelianTiket;
