import React, { useState, useEffect } from "react";
import { useApiPost } from "../../hooks/useApiPost";
import {
  Box,
  Flex,
  Input,
  Button,
  Text,
  Heading,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { X } from "lucide-react";
import CancelledCard from "../../components/CancelledCard";
import { useFetchManualTrigger } from "../../hooks/useFetchManualTrigger";
import LoadingOverlay from "../../components/ui/LoadingOverlay";
import toastHandler from "../../components/toastHandler";

const CancelledOrder = () => {
  const [reservationNumber, setReservationNumber] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const toast = useToast();
  const {
    handlePost: cancelledPost,
    loading: cancelledLoading,
    data: cancelledData,
    error: cancelledError,
  } = useApiPost();
  const {
    loading: detailLoading,
    data: detailData,
    error: detailError,
    fetchData: detailFetchData,
  } = useFetchManualTrigger();

  useEffect(() => {
    if (!cancelledLoading && cancelledData) {
      setReservationNumber("");
      setShowDetails(false);
    }
    toastHandler({
      data: cancelledData,
      error: cancelledError,
      loading: cancelledLoading,
      toast: toast,
      title: "Berhasil membatalkan order",
    });
  }, [cancelledLoading, cancelledData, cancelledError]);

  const handleSearch = () => {
    setShowDetails(true);
    detailFetchData(`/api/reservations/${reservationNumber}`);
  };

  const clearSearch = () => {
    setReservationNumber("");
    setShowDetails(false);
  };

  const handleCancel = (data) => {
    cancelledPost("/api/update-status-transaction", data);
  };

  return (
    <Flex h="100vh" w="full">
      <Box flex={1} bg="gray.50">
        <LoadingOverlay />
        <Box p={6}>
          <Box
            bg="white"
            p={6}
            rounded="md"
            shadow="sm"
            borderWidth="1px"
            borderColor="gray.100"
            mb={6}
          >
            <Heading size="md" mb={6}>
              Cari Pesanan
            </Heading>

            <Box mb={2}>
              <Text mb={2}>Nomor Reservasi</Text>
              <InputGroup>
                <Input
                  value={reservationNumber}
                  onChange={(e) => setReservationNumber(e.target.value)}
                  placeholder="Masukkan nomor reservasi"
                  size="lg"
                  borderRadius="md"
                />
                {reservationNumber && (
                  <InputRightElement h="full">
                    <Button
                      variant="ghost"
                      onClick={clearSearch}
                      aria-label="Clear search"
                    >
                      <X size={16} />
                    </Button>
                  </InputRightElement>
                )}
              </InputGroup>
              <Text fontSize="sm" color="gray.500" mt={1}>
                Masukkan nomor reservasi untuk mencari pesanan yang ingin
                dibatalkan
              </Text>
            </Box>

            <Button
              mt={4}
              colorScheme="teal"
              bg="teal.400"
              onClick={handleSearch}
            >
              Cari Pesanan
            </Button>
          </Box>

          {showDetails && (
            <CancelledCard
              loading={detailLoading}
              error={detailError}
              data={detailData?.data}
              handleCancel={handleCancel}
            />
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default CancelledOrder;
