import React from "react";

import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils";

const labelVariants = cva(
  "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);

export const InputGroup = ({
  containerClassName,
  label,
  registerLabel,
  register,
  validation = {},
  type,
  dataTestId,
}) => {
  return (
    <div className={cn("flex flex-col gap-2", containerClassName)}>
      <label className={cn(labelVariants())}>{label}</label>
      <input
        type={type}
        {...register(registerLabel, validation)}
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        data-testid={dataTestId}
      />
    </div>
  );
};
