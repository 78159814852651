import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Heading,
  Divider,
  Textarea,
} from "@chakra-ui/react";
import { X } from "lucide-react";
import _ from "lodash";
import LoadingOverlay from "./ui/LoadingOverlay";
import { numberToRupiah } from "../lib/utils";

const CancelledCard = ({ loading, error, data, handleCancel }) => {
  const [cancelReason, setCancelReason] = useState("");
  const totalPrice = _.sumBy(data?.skus || [], (item) => item.price * item.qty);
  if (error)
    return (
      <Box
        bg="white"
        p={6}
        rounded="md"
        shadow="sm"
        borderWidth="1px"
        borderColor="gray.100"
      >
        <Text className="text-systemRed text-center font-extrabold">
          {error.message}
        </Text>
      </Box>
    );

  return (
    <Box
      bg="white"
      p={6}
      rounded="md"
      shadow="sm"
      borderWidth="1px"
      borderColor="gray.100"
    >
      <LoadingOverlay />
      <Heading size="md" mb={6}>
        Detail Pesanan
      </Heading>

      <Flex flexWrap="wrap" gap={6} mb={6}>
        <DetailItem
          label="Nomor Reservasi"
          value={data?.reservation_id}
          width="45%"
        />
        <DetailItem
          label="Nama Leader"
          value={data?.tour_leader_name}
          width="45%"
        />
        <DetailItem
          label="Nomor Telepon"
          value={data?.phone_number}
          width="45%"
        />
        <DetailItem label="Nama Group" value={data?.group_name} width="45%" />
        <DetailItem
          label="Tanggal Penggunaan"
          value={data?.use_date}
          width="45%"
        />
        <DetailItem label="Dibuat Oleh" value={data?.created_by} width="45%" />
      </Flex>

      <Heading size="md" mb={4}>
        Detail Tiket
      </Heading>

      <Box mb={4}>
        {data?.skus?.map((sku, idx) => {
          return (
            <Flex justify="space-between" py={2}>
              <Text>{sku?.sku_name + " x " + sku?.qty}</Text>
              <Text>{numberToRupiah(sku?.price || 0)}</Text>
            </Flex>
          );
        })}
        <Divider my={2} />
        <Flex justify="space-between" py={2} fontWeight="bold">
          <Text>Total</Text>
          <Text>{numberToRupiah(totalPrice || 0)}</Text>
        </Flex>
      </Box>
      <Box>
        <Text className="font-bold" mb={2}>
          Alasan di batalkan
        </Text>
        <Textarea
          size="lg"
          onChange={(e) => setCancelReason(e.target.value)}
          value={cancelReason}
        />
      </Box>

      <Button
        mt={4}
        colorScheme="red"
        leftIcon={<X size={16} />}
        onClick={() => {
          handleCancel({
            reservation_id: data?.reservation_id,
            status: "CANCELLED",
            cancel_reason: cancelReason,
          });
          setCancelReason("");
        }}
      >
        Batalkan Pesanan
      </Button>
    </Box>
  );
};

function DetailItem({ label, value, width = "100%" }) {
  return (
    <Box width={width}>
      <Text color="gray.500" fontSize="sm" mb={1}>
        {label}
      </Text>
      <Text fontWeight="medium">{value}</Text>
    </Box>
  );
}

export default CancelledCard;
